<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader style="display: flex; justify-content: space-between">
                <span style="font-size: 20px"> Solicitudes de Atención </span>
                <!--                 <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="updateUser(false, $event)"
                >
                  Agregar
                </CButton> -->

                <p style="font-size: 18px">
                  <span style="font-weight: 500">Total:</span>
                  {{ totalAttentions }}
                </p>
              </CCardHeader>
              <CCardBody>
                <div class="d-flex justify-content-between" style="height: 80px;">
                <div style="display: flex;gap: 16px;align-items: center;justify-content: space-between;">
<!--                 <CButton
                style="width: 15px;height: 21px;display: flex;justify-content: center;align-items: center;"
                @click="collapseFilter = !collapseFilter"
                color="primary"
                class="mb-2"
              >
                <font-awesome-icon
                  :icon="!collapseFilter ? 'plus' : 'minus'"
                  style="font-size: 12px"
                />
              </CButton> -->
                  <!-- Filtros -->
                <CCollapse :show="collapseFilter">
                <div style="display:flex; gap: 20px; align-items:center;">
                  <div>
                    <p>Filtrar por horario:</p>
                    <CSelect :options="schedule_options" :value.sync="schedule_filterId" />
                  </div>
                  <div>
                    <p>Respuesta Atención:</p>
                    <CSelect :options="answer_options" :value.sync="answered" />
                  </div>
                  <div>
                    <p>Versión:</p>
                    <CSelect :options="version_options" :value.sync="version" />
                  </div>
                  <div>
                    <div class="d-flex align-items-center justify-content-center;">
                        <p class="mb-0 mr-2">Ocultar Atendidos Hoy:</p> <CInputCheckbox :checked.sync="daily_attention_validation" style="height: 20px;"/> 
                  </div>
                  </div>
                </div>
                </CCollapse>
                </div>

                  <div style="display: flex; align-items: center">
                    <CSpinner
                      color="primary"
                      v-if="loaderSearch"
                      size="sm"
                      class="mb-3 mr-2"
                    />
                    <font-awesome-icon icon="search" class="mb-3 mr-2" v-else />
                    <CInput v-model="search" placeholder="Buscar usuario" />
                  </div>
                </div>
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="attentions"
                  :fields="fields"
                  :items-per-page="limit"
                >
                  <!--                   <template #telefono="data">
                    <td>{{data.item.info.telefono}}</td>
                  </template>
                  <template #direccion="data">
                    <td>{{data.item.info.direccion}}</td>
                  </template>
                  <template #cuadrante="data">
                    <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
                  </template> -->
                  <template #name="data">
                    <td>
                      {{ data.item.name }}
                      <br />
                      <div class="badge-answered" :style= "[data.item.answered == 'Ha Contestado' ? {background: '#008100'} : {background: '#fc0818'}]">
                        {{ data.item.answered }}
                      </div>
                    </td>
                  </template>
                  <template #last_attention="data">
                    <td v-if="data.item.last_attention.name">
                      <span style="font-weight: 500; margin-bottom: 0; padding-bottom: 0">
                        {{ data.item.last_attention.name }}
                      </span>
                      <br />
                      <span style="font-size: 12px; margin-bottom: 0; padding-bottom: 0">
                        {{ data.item.last_attention.date }}
                      </span>
                    </td>
                    <td v-else>
                      {{ data.item.last_attention }}
                    </td>
                  </template>
                  <template #videocall="data">
                    <div style="margin-left: 36px; margin-top: 4px">
                      <CButton
                        v-c-tooltip="'LLamar'"
                        color="success"
                        size="sm"
                        class="m-1"
                        @click="openModalVideoCall(data.item.user_id)"
                      >
                        <font-awesome-icon icon="phone" />
                      </CButton>
                    </div>
                  </template>
                </CDataTable>
                <div style="width: 200px; margin: 0 auto">
                  <CPagination
                    :active-page.sync="page"
                    :pages="Math.ceil(totalAttentions / limit)"
                    align="center"
                    size="lg"
                  />
                </div>
                <div
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center"
                  style="min-height: 300px"
                >
                  <CSpinner style="width: 4rem; height: 4rem" color="danger" />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </transition>
    </CCol>
    <ModalVideoCall
      :user_is_in_attention="user_is_in_attention"
      :modalVideoCall="modalVideoCall"
      :close="close"
      :user_id="user_id"
    />
  </CRow>
</template>

<script>
import { getUsersAttentions, checkUserInAttention } from "../../api/atenciones";
import ModalVideoCall from "./ModalVideoCall.vue";
import moment from "moment";
import _ from "lodash/lodash";

export default {
  components: { ModalVideoCall },
  data() {
    return {
      //tabla
      page: 1,
      limit: 10,
      totalAttentions: 0,
      loading: true,
      attentions: [],
      fields: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "schedule_type",
          label: "Preferencia horaria",
          _props: { scope: "col" },
        },
        {
          key: "last_attention",
          label: "Última Atención",
          _props: { scope: "col" },
        },
        {
          key: "videocall",
          label: "Video LLamada",
          _props: { scope: "col" },
        },
      ],
      schedule_types: {
        1: "Entre 09:00 y 12:00",
        2: "Entre 12:00 y 15:00",
        3: "Entre 15:00 y 18:00",
      },
      schedule_options: [
        { label: "Entre 09:00 y 12:00", value: 1 },
        { label: "Entre 12:00 y 15:00", value: 2 },
        { label: "Entre 15:00 y 18:00", value: 3 },
        { label: "Todos", value: null },
      ],
      answer_options: [
        { label: "Todos", value: 0 },
        { label: "Contestó", value: 1 },
        { label: "No Contestó", value: 2 },
      ],
      answered: 0,
      version_options: ["Todas", "0.25.54", "0.25.53", "0.25.52", "0.25.51", "0.25.50", "0.25.49", "0.25.48", "0.25.47", "0.25.46", "0.25.45", "0.25.44", "0.25.43" ,"0.25.42", "0.25.41","0.25.40", "0.25.36"],
      schedule_filterId: null,
      version: "Todas",
      daily_attention_validation: true,
      //modal videocall props
      modalVideoCall: false,
      user_id: null,
      user_is_in_attention: null,

      search: "",
      loaderSearch: false,
      collapseFilter:true
    };
  },
  methods: {
    getAttentions() {
      this.loading = true;
      const data = {
        page: this.page,
        limit: this.limit,
        hour_preference_segment: this.schedule_filterId,
        search: this.search,
        daily_attention_validation: this.daily_attention_validation ? 1 : 0,
        answered: this.answered,
        min_app_version: this.version
      };
      if (this.version == "Todas") {
        delete data.min_app_version
      }
      getUsersAttentions(data).then((res) => {
          this.loading = false;
          this.totalAttentions = res.attention_request_total;
          this.attentions = res.attention_request.map((attention) => {
            this.loaderSearch = false;
            return {
              user_id: attention?.user_id,
              name: attention?.user?.name,
              schedule_type: attention?.hour_preference_segment ? this.schedule_types[attention?.hour_preference_segment] : "No especificado",
              last_attention: attention?.last_attention ? {
                    name: attention?.last_attention?.caller?.name,
                    date: moment(attention?.last_attention?.start).format("DD-MM-YYYY - HH:mm"),
                  }
                : "Sin Registro",
                answered: attention?.answered ? 'Ha Contestado' : 'No Ha Contestado'
            };
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openModalVideoCall(user_id) {
      checkUserInAttention({ user_id })
        .then((res) => {
          this.modalVideoCall = true;
          this.user_id = user_id;
          this.user_is_in_attention = res.user_is_in_attention;

          if (res.user_is_in_attention) {
            this.getAttentions();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    close() {
      this.modalVideoCall = false;
    },
  },
  mounted() {
    this.getAttentions();
  },
  watch: {
    page: _.debounce(function (page) {
      this.getAttentions();
    }, 500),
    schedule_filterId() {
      this.getAttentions();
    },
    version() {
      this.getAttentions();
    },
    daily_attention_validation() {
      this.getAttentions();
    },
    answered() {
      this.getAttentions();
    },
    search: _.debounce(function (value) {
      if (this.search.length > 2 || this.search == "") {
        this.loaderSearch = true;
        this.getAttentions();
      }
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.badge-answered {
  display: flex;
  width: 100px;
  color: #fff;
  justify-content: center;
  border-radius: 15px;
  font-weight: 500;
  font-size: 10px;
  padding: 2px 0;
}
</style>
